@import "@/app/styles/_mantine.scss";
.base-toast {

	&__container {

		--toastify-toast-width: 360px;
	}

	&__item-wrapper {
		min-height: 55px;
		padding: 0 10px;
		border-radius: var(--mantine-radius-default);
		font-weight: 600;
		color: white;

		&.Toastify__toast--error {
			// color: var(--mantine-color-red-8);
			background: var(--mantine-color-red-6);
			border-color: var(--mantine-color-red-3);
		}

		&.Toastify__toast--success {
			color: var(--mantine-color-dark-4);
			background: var(--mantine-color-green-3);
			border-color: var(--mantine-color-green-3);

			.base-toast__item-close-btn {
				color: var(--mantine-color-dark-4);
			}
		}
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__item-text-content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		font-family: 'IBM Plex Mono', monospace;
		margin-left: auto;
		line-height: 1;
	}

	&__item-icon {
		width: 30px;
		height: 30px;
		margin-right: 10px;
		display: flex;
		align-items: center;
	}

	&__item-title {
		margin: 0;
	}

	&__item-description {
		margin: 0;
	}

	&__item-actions {
		display: flex;
		margin-left: auto;
	}

	&__item-close-btn {
		background-color: transparent;
		border: none;
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: .3s;
		color: white;
	}
}
